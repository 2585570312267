/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";



body {
    background-color: #f2f2f2 !important;
    background-image: url('assets/images/bReplyLogo2.png');
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: 550px 100px; 

    overflow-x: hidden;
}